var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "tab-card" },
    [
      _c(
        "v-card-title",
        { staticClass: "tab-card-title" },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tabs-slider", { attrs: { color: "#00FF99" } }),
              _vm._l(_vm.items, function (item) {
                return _c("v-tab", { key: item }, [
                  _c("div", { staticClass: "tab-text" }, [
                    _vm._v(_vm._s(item)),
                  ]),
                ])
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(_vm.items, function (item) {
          return _c("v-tab-item", { key: item }, [_c("TabGeneralVision")], 1)
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }