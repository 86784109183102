<template>
  <v-card class="tab-card">
    <v-card-title class="tab-card-title">
      <v-tabs v-model="tab">
        <v-tabs-slider :color="'#00FF99'"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">
          <div class="tab-text">{{ item }}</div>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item">
        <TabGeneralVision />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  name: 'TabsRubrica',
  components: {
    TabGeneralVision: () => import('./TabGeneralVision.vue'),
  },
  data() {
    return {
      tab: null,
      items: ['Visão Geral'],
    };
  },
};
</script>

<style lang="scss" scoped>
.tab-card {
  padding: 0px !important;
  border: 0px !important;
}
.tab-card-title {
  padding: 0px !important;
  background-color: #f7f7fd !important;
  border: 1px solid #e7e7fa !important;
}
::v-deep .v-tabs-bar {
  background-color: transparent !important;
}
</style>
